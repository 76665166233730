<script lang="ts" setup>
const emits = defineEmits<{
  (e: 'goHome'): void;
  (e: 'goBack'): void;
}>();

const onGoHome = (): void => {
  emits('goHome');
};

const onGoBack = (): void => {
  emits('goBack');
};
</script>

<template>
  <div class="h-full w-full flex flex-col items-center justify-center text-gray-900">
    <span class="error-code">404</span>

    <h1 class="text-4xl font-bold leading-8">
      Sorry, page not found
    </h1>

    <div class="flex justify-center gap-2 py-4">
      <UButton
        size="xl"
        icon="ic:baseline-home"
        label="Home"
        @click="onGoHome"
      />
      <UButton
        size="xl"
        icon="ic:round-chevron-left"
        label="Back"
        @click="onGoBack"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-code {
  font-size: 10rem;
  font-weight: bold;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
    rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
