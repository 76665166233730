import revive_payload_client_qhuESlupSQ from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_p96kbXZhZN from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kQa1F74L0B from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zvTN6HlzBl from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1.77._q46i4zibcqv5y62xjghkmimdd4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_MnhS9vkIwP from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tqax5SRMbl from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2DRgffsOPh from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cPkSORDazz from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_59O68PzUXW from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_E0Rs7rNXoG from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_1C0XLaP4uP from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.3.9_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass_jy6fnnryqmbemg5jvctzgd5pyu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_zcO2aNOdSd from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.3.9_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass_jy6fnnryqmbemg5jvctzgd5pyu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_aFOb5dXdF2 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sa_eeop762ffe7vbyxwobpbeumvdq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_nYopz71tHt from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sa_eeop762ffe7vbyxwobpbeumvdq/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_mTxT2zIdA3 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.4_rollup@4.20.0_vite@5.4.0_@types+_new7z62akdvqp2rf66ovlfk4oe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_GkNLX0tkDY from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.4_rollup@4.20.0_vite@5.4.0_@types+_new7z62akdvqp2rf66ovlfk4oe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_u2LCmdPohh from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.4_rollup@4.20.0_vite@5.4.0_@types+_new7z62akdvqp2rf66ovlfk4oe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_kMFpnxgd3E from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_KrZTVbr1OI from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1.77.8_terse_5m5lzzr4bchv5gcgqpojr3s6xi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_MXdeG2VVJt from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1.77.8_t_gseh7o74prcm4mrj4m3pkmu5cu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_ebbEeKltax from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.1.0_sass@1.77.8_t_gseh7o74prcm4mrj4m3pkmu5cu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_LwzXRlA7mD from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_2tjAJJu7oF from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typesc_ni56l37fty6oqqxgtsmogcbup4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import services_eN9fWZLDQ0 from "/app/plugins/services.ts";
export default [
  revive_payload_client_qhuESlupSQ,
  unhead_p96kbXZhZN,
  router_kQa1F74L0B,
  _0_siteConfig_zvTN6HlzBl,
  payload_client_MnhS9vkIwP,
  navigation_repaint_client_tqax5SRMbl,
  check_outdated_build_client_2DRgffsOPh,
  chunk_reload_client_cPkSORDazz,
  plugin_vue3_59O68PzUXW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_E0Rs7rNXoG,
  titles_1C0XLaP4uP,
  defaults_zcO2aNOdSd,
  siteConfig_aFOb5dXdF2,
  inferSeoMetaPlugin_nYopz71tHt,
  slideovers_mTxT2zIdA3,
  modals_GkNLX0tkDY,
  colors_u2LCmdPohh,
  plugin_client_kMFpnxgd3E,
  plugin_KrZTVbr1OI,
  pwa_icons_MXdeG2VVJt,
  pwa_client_ebbEeKltax,
  plugin_LwzXRlA7mD,
  plugin_2tjAJJu7oF,
  directives_8CcCirWtnE,
  services_eN9fWZLDQ0
]