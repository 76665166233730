import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._kq52hckawwjb66eg5lxpgd35l4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-desktop": () => import("/app/middleware/account-desktop.ts"),
  "account-mobile": () => import("/app/middleware/account-mobile.ts"),
  "auth-and-menu": () => import("/app/middleware/auth-and-menu.ts"),
  "auth-or-guest": () => import("/app/middleware/auth-or-guest.ts"),
  "auth-or-home": () => import("/app/middleware/auth-or-home.ts"),
  checkout: () => import("/app/middleware/checkout.ts"),
  "confirm-email": () => import("/app/middleware/confirm-email.ts")
}